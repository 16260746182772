import createButtonClickLog from './createButtonClickLog'
import { pushLocation } from './pushLocation'

export const handleLinkButtonClick = (
  postUID: string,
  buttonId: string | null,
  link: string | null,
  type?: string | null,
) => {
  if (buttonId && type) {
    // logging
    createButtonClickLog(
      {
        post_id: postUID,
        button_id: buttonId,
        country: (process.env.COUNTRY_CODE || 'kr') as CountryCode,
      },
      type,
    )
  }
  pushLocation(link, {
    post_id: postUID,
    button_id: buttonId,
    country: (process.env.COUNTRY_CODE || 'kr') as CountryCode,
  })
  return
}
