import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'

import CommonImageContainer from './commons/CommonImageContainer'
import GatsbyImageComponent from './commons/GatsbyImage'

import getGatsbyImage from '../utils/getGatsbyImage'
import { handleReplaceLinkButtonClick } from '../utils/handleReplaceLinkButtonClick'

export default function ReplaceLoggingButtonSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyTypeReplaceLogginButton
}) {
  const item = slice.primary
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const image = getGatsbyImage(item.replace_logging_button_image?.localFile)

  return (
    <Fragment>
      <CommonImageContainer
        onClick={() =>
          handleReplaceLinkButtonClick(
            postUID,
            item.replace_logging_button_id,
            item.replace_logging_button_app_link,
            'normal-btn',
          )
        }
      >
        {image && (
          <GatsbyImageComponent
            imagefile={image}
            alt={item.replace_logging_button_image?.alt ?? ''}
          />
        )}
      </CommonImageContainer>
    </Fragment>
  )
}
