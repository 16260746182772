import { captureMessage } from '@sentry/gatsby'

import { bridge, logAnalyticsEvent } from '../../../bridge'
import createUrlPath from './createUrlPath'
import { parseUserAgentInfo } from './parseUserAgentInfo'
import {
  payWebviewAvailableVersionCheck,
  isPayWebViewScheme,
} from './payWebviewAvailableVersionCheck'
import { locateToAppDownLoadUrl } from './locateToAppDownLoadUrl'

async function checkPayWebviewVersion(scheme?: string | null) {
  /**
   * pay-web Route의 경우 버전을 체크하고 페이웹뷰 지원되는 버전이면 true, 지원 버전 미만인 경우 false를 반환하며,
   * 그 이외 route의 경우는 true를 반환.
   */
  try {
    const appVersionInfo = await bridge?.getAppInfo({})

    if (appVersionInfo?.info.app.userAgent && isPayWebViewScheme(scheme)) {
      const [version, os] = parseUserAgentInfo(
        appVersionInfo?.info.app.userAgent,
      )
      return payWebviewAvailableVersionCheck(version, os as OStype)
    }
    return true
  } catch (err) {
    captureMessage('앱 버전 정보를 가져오는데 실패했어요.')
    return true
  }
}

export async function pushLocation(
  link?: string | null,
  context?: Record<string, any>,
) {
  try {
    if (!link) {
      return
    }
    // check if push to pay webview and if so whether user is using available version or not
    if (!(await checkPayWebviewVersion(link))) {
      const updateAlertModal = window.confirm(
        '당근페이는 최신 버전으로 업데이트해야 이용할 수 있어요. 앱을 업데이트 하시겠어요?',
      )
      if (updateAlertModal) {
        locateToAppDownLoadUrl()
        logAnalyticsEvent('click_payweb_button_old_version', {
          ...context,
        })
      }
      return
    }

    // routing
    const result = createUrlPath(link)

    // minikarrot route가 아닌 경우는 window.location.href로 이동시켜줘야 함.
    if (result?.useWindowLocation) {
      window.location.href = link
    }

    if (result?.wv === 'remote') {
      bridge?.pushRouter({
        router: {
          remote: result.remote as string,
          navbar: true,
          scrollable: true,
          enableSafeAreaInsets: true,
        },
      })
    }

    if (result?.wv === 'local') {
      bridge?.pushRouter({
        router: {
          app: result.app as string,
          path: result.path as string,
          navbar: true,
          scrollable: true,
          enableSafeAreaInsets: true,
        },
      })
    }
    return
  } catch (err) {
    captureMessage(
      `링크를 이동하는 도중 에러가 발생하였어요. - ${JSON.stringify(err)}`,
    )
    alert('링크를 이동하는 도중 에러가 발생하였어요. 새로고침 후 시도해주세요.')
  }
}
