import { getImage } from 'gatsby-plugin-image'
import type { ImageDataLike } from 'gatsby-plugin-image'

export default function getGatsbyImage(
  localFile?: Queries.Maybe<Queries.File>,
) {
  const image = localFile as ImageDataLike | undefined
  const gatsbyImageData = image ? getImage(image) : null

  return gatsbyImageData
}
