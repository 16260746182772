import React, { Fragment, useMemo, useRef } from 'react'
import { styled } from '@stitches/react'
import { vars } from '@seed-design/design-token'

import { Button } from './Button'
import useOutboundClick from '../../../../hooks/useOutBoundClick'

interface ModalPopupContainerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: Queries.Maybe<string>
  buttonItems?: readonly Queries.PrismicEventPageDataBodyModalButtonItem[]
  shadow?: Queries.Maybe<string>
  onClickMoveURLButton?: (url: string) => void
  onClickModalOffButton?: () => void
  onClickOutBoundModal?: () => void
}

export const ModalPopupContainer: React.FC<ModalPopupContainerProps> = ({
  title,
  buttonItems,
  shadow,
  onClickMoveURLButton,
  onClickModalOffButton,
  onClickOutBoundModal,
  children,
  ...props
}) => {
  const PopupContainerRef = useRef<HTMLDivElement>(null)
  const lastIndex = useMemo(
    () => buttonItems && buttonItems.length - 1,
    [buttonItems],
  )

  const handleClickModalButton = (
    buttonItem: Queries.PrismicEventPageDataBodyModalButtonItem,
  ) => {
    if (buttonItem.modal_button_button_type === 'MOVE_URL') {
      onClickMoveURLButton &&
        onClickMoveURLButton(buttonItem.modal_button_button_target_uri || '')
    }
    if (buttonItem.modal_button_button_type === 'MODAL_OFF') {
      onClickModalOffButton && onClickModalOffButton()
    }
    return
  }

  useOutboundClick(PopupContainerRef, {
    outboundFunction: () => {
      onClickOutBoundModal && onClickOutBoundModal()
    },
  })

  return (
    <ModalPopupContent
      {...props}
      css={{ boxShadow: `0px 6px 14px ${shadow || 'none'}` }}
      ref={PopupContainerRef}
    >
      <ModalInnerContentWrapper>
        <ModalPopupTitle>{title}</ModalPopupTitle>
        <div style={{ padding: 16 }} />
        <ButtonSection>
          {buttonItems &&
            buttonItems.map((button, idx) => {
              return (
                <Fragment key={idx}>
                  <Button
                    buttonText={button.modal_button_button_text || '확인'}
                    buttonTheme={
                      button.modal_button_button_color || 'SECONDARY'
                    }
                    fontSize="h5"
                    onClick={() => handleClickModalButton(button)}
                  />
                  {!(idx === lastIndex) && <div style={{ padding: 5 }} />}
                </Fragment>
              )
            })}
          {(!buttonItems || !buttonItems.length) && (
            <Button
              buttonText={'확인'}
              buttonTheme={'SECONDARY'}
              onClick={onClickModalOffButton}
            />
          )}
        </ButtonSection>
      </ModalInnerContentWrapper>
    </ModalPopupContent>
  )
}

const ModalPopupContent = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0px)',
  width: '75%',
  maxWidth: 550,
  borderRadius: 16.3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: vars.$static.color.staticWhite,
})

const ModalInnerContentWrapper = styled('div', {
  padding: 40,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const ModalPopupTitle = styled('h3', {
  color: vars.$scale.color.gray900,
  textAlign: 'center',
  wordBreak: 'keep-all',
  fontWeight: 'normal',
})

const ButtonSection = styled('div', {
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
})

export default ModalPopupContainer
