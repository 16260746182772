export default function createUrlPath(url: string) {
  const urlObject = new URL(url)
  const protocol = urlObject.protocol
  const isKarrotProtocol =
    protocol === 'karrot:' || protocol === 'karrot.alpha:'
  const isMiniKarrotRoute = url.indexOf('minikarrot') > -1
  const searchParams = []

  for (let param of urlObject.searchParams) {
    searchParams.push(param)
  }
  const isRemoteWebView = searchParams.filter((param) => param[0] === 'remote')
  const isLocalWebView = searchParams.filter((param) => param[0] === 'app')
  const pathParamOfLocalWebView = isLocalWebView.length
    ? searchParams.filter((param) => param[0] === 'path')[0][1]
    : undefined

  if (!isMiniKarrotRoute) {
    // minikarrot route가 아닌 경우는 window.location.href를 사용해야 합니다.
    return {
      useWindowLocation: true,
      protocol: protocol,
      wv: null,
      href: urlObject.href,
    }
  }
  if (!isKarrotProtocol) {
    return {
      useWindowLocation: false,
      protocol: protocol,
      wv: 'remote',
      href: urlObject.href,
    }
  }
  if (isRemoteWebView.length) {
    return {
      useWindowLocation: false,
      protocol: protocol,
      wv: 'remote',
      remote: isRemoteWebView[0][1],
    }
  }
  if (isLocalWebView.length) {
    return {
      useWindowLocation: false,
      protocol: protocol,
      wv: 'local',
      app: isLocalWebView[0][1],
      path: pathParamOfLocalWebView,
    }
  }
}
