import { keyframes } from "@stitches/react";

export const smoothDownAnimation = keyframes({
  "0%": {
    maxHeight: 0,
  },
  "100%": {
    maxHeight: 100,
  },
});

export const smoothUpAnimation = keyframes({
  "0%": {
    maxHeight: 100,
  },
  "100%": {
    maxHeight: 0,
  },
});
