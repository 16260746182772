import React from 'react'
import { styled } from 'gatsby-theme-stitches/src/config'
import { SliceZone } from '@prismicio/react'
import { vars } from '@seed-design/design-token'

import HeaderSlice from '../components/HeaderSection'
import ImageSection from '../components/ImageSection'
import FaqSlice from '../components/FAQSection'
import CardsCarousel from '../components/CardsCarouselSection'
import EmbedSection from '../components/EmbedSection'
import LoggingButtonSection from '../components/LoggingButtonSection'
import ShareButtonSection from '../components/ShareButtonSection'
import FloatingButtonSlice from '../components/FloatingButtonSection'
import ModalButtonSection from '../components/ModalButtonSection'
import HeroSection from '../components/HeroSection'
import ReplaceLoggingButtonSection from '../components/ReplaceLoggingButtonSection'
import AmplitudeEventLogSlice from '../components/AmplitudeEventLog'
import YoutubeShortsSection from '../components/YoutubeShortsSection'

import type { PrismicDocument } from '@prismicio/types'

const $semantic = vars.$semantic

export function ContentSection({
  document,
}: {
  document?: PrismicDocument<Record<string, any>, string, string>
}) {
  const isFullWidth = document?.tags
    .map((tag) => tag.trim())
    .includes('full_width')
  if (!document) {
    return null
  }

  return (
    <Wrapper fullPage={isFullWidth}>
      {document && (
        <SliceZone
          slices={document.data.body as any[]}
          components={{
            header: HeaderSlice,
            image_section: ImageSection,
            faq_section: FaqSlice,
            cards_carousel: CardsCarousel,
            embed_content: EmbedSection,
            youtube_shorts: YoutubeShortsSection,
            logging_button: LoggingButtonSection,
            share_button: ShareButtonSection,
            floating_button: FloatingButtonSlice,
            modal_button: ModalButtonSection,
            hero_media: HeroSection,
            _type_replace_loggin_button: ReplaceLoggingButtonSection,
            amplitude_event_log: AmplitudeEventLogSlice,
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div', {
  padding: 16,
  backgroundColor: $semantic.color.paperDefault,
  lineHeight: 0,
  variants: {
    fullPage: {
      true: {
        padding: 0,
      },
      false: {},
    },
  },
  marginTop: 'env(safe-area-inset-top)',
})
