import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'

import { useBridge } from '../../../bridge'

import CommonImageContainer from './commons/CommonImageContainer'
import GatsbyImageComponent from './commons/GatsbyImage'

import { createButtonClickLog } from '../utils/createButtonClickLog'
import getGatsbyImage from '../utils/getGatsbyImage'

export default function ShareButtonSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyShareButton
}) {
  const bridge = useBridge()
  const item = slice.primary
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const image = getGatsbyImage(item.share_button_image?.localFile)

  const handleClickShareButton = (
    shareUrl: string | null,
    shareText: string | null,
  ) => {
    try {
      if (shareUrl) {
        bridge?.share({
          share: {
            text: shareText || '당근 공유하기',
            url: shareUrl,
          },
        })
        if (item.share_button_image_id) {
          createButtonClickLog(
            {
              post_id: postUID,
              button_id: item.share_button_image_id,
              country: (process.env.COUNTRY_CODE || 'kr') as CountryCode,
            },
            'share-btn',
          )
        }
      }
    } catch (err) {
      alert(
        '공유 버튼을 활성화 하는 중 문제가 생겼어요. 불편을 드려 죄송해요. \n 앱의 버전 문제이거나, 기기의 버전 문제일 수 있어요.',
      )
    }
  }

  return (
    <Fragment>
      <CommonImageContainer
        id={item.share_button_image_id ?? ''}
        onClick={() =>
          handleClickShareButton(
            item.share_button_share_link,
            item.share_button_share_text,
          )
        }
      >
        {image && (
          <GatsbyImageComponent
            imagefile={image}
            alt={item.share_button_image?.alt ?? ''}
          />
        )}
      </CommonImageContainer>
    </Fragment>
  )
}
