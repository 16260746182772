import React, { useMemo } from 'react'
import { styled, css } from '@stitches/react'
import Slider from 'react-slick'
import { vars } from '@seed-design/design-token'

import SlideItem from './commons/SlideItem'
import LeftArrow from '../assets/LeftArrow'
import RightArrow from '../assets/RightArrow'

import PrismicRichTextComponent from './commons/PrismicRichTextComponent'
import DescriptionSection from './commons/DescriptionSection'

function NextArrow(props: any) {
  const { className, style, onClick } = props
  return <RightArrow className={className} onClick={onClick} {...style} />
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props
  return <LeftArrow className={className} onClick={onClick} {...style} />
}

export default function CardsCarousel({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyCardsCarousel
}) {
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      draggable: true,
      speed: 350,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      appendDots: (dots: any) => (
        <div
          style={{
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <SliderDots style={{ margin: '0px' }}> {dots} </SliderDots>
        </div>
      ),
    }),
    [],
  )
  const ContainerStyle = css({
    backgroundColor:
      slice.primary.cards_carousel_background_color_light || 'none',
  })()

  const SlickListStyle = css({
    '& .slick-list': {
      height: `${slice.primary.cards_carousel_slick_list_height ?? 350}px`,
    },
  })()

  return (
    <Container className={ContainerStyle}>
      <DescriptionSection>
        {slice.primary.cards_carousel_description ? (
          <PrismicRichTextComponent
            {...slice.primary.cards_carousel_description}
          />
        ) : (
          <></>
        )}
      </DescriptionSection>
      <Slider {...settings} className={SlickListStyle}>
        {slice.items.map((item, index) => (
          <SlideItem key={slice.id + index} {...item} />
        ))}
      </Slider>
    </Container>
  )
}

const Container = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 40px',
  '& svg > path': {
    fill: vars.$scale.color.gray900,
  },
  '& .slick-disabled': {
    cursor: 'not-allowed',
    '& > path': {
      fill: vars.$scale.color.gray200,
      stroke: vars.$scale.color.gray200,
    },
  },
  '& .slick-list': {
    '& div': {
      height: '100%',
    },
    '& > div > div > div > div > div': {
      paddingBottom: 0,
    },
    '& img': {
      height: '100%',
      paddingBottom: 0,
      width: '100%',
      objectFit: 'contain !important',
    },
  },
})

const SliderDots = styled('ul', {
  '& .slick-active > button::before': {
    color: vars.$scale.color.gray900 + '!important',
  },
  '& li > button::before': {
    color: vars.$scale.color.gray700 + '!important',
  },
})
