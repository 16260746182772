import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, PageProps } from 'gatsby'
import { ErrorBoundary } from '@sentry/gatsby'
import queryString from 'query-string'

import { BridgeProvider } from '../../bridge'
import DaangnPrismicViewer from '../../components/PrismicViewer'
import useSetSeedDesign from '../../hooks/useSetSeedDesign'
import useAddPVCount from '../../hooks/useAddPVCount'
import { Seo } from '../../components/Seo'
import ErrorPage from '../ErrorPage'

const ContentPage = ({ data }: PageProps<any>) => {
  if (!data) {
    return <ErrorPage />
  }
  const doc = data.prismicEventPage
  const location = useLocation()
  const searchParams = queryString.parse(location.search)

  useSetSeedDesign()
  useAddPVCount(data.prismicEventPage.uid, {
    tags: doc.tags,
    ...searchParams,
  })

  return (
    <main>
      <Seo
        author={doc.data.meta_author}
        title={doc.data.meta_title}
        description={doc.data.meta_description}
        ogImage={doc.data.meta_image.url}
      />
      <ErrorBoundary fallback={<ErrorPage />}>
        <BridgeProvider>
          {doc && <DaangnPrismicViewer document={doc as any} />}
        </BridgeProvider>
      </ErrorBoundary>
    </main>
  )
}

export const query = graphql`
  query ContentPageQuery($uid: String) {
    prismicEventPage(uid: { eq: $uid }) {
      _previewable
      prismicId
      uid
      tags
      data {
        meta_title
        meta_description
        meta_author
        meta_image {
          url
        }
        body {
          ... on PrismicEventPageDataBodyCardsCarousel {
            id
            slice_type
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
            primary {
              cards_carousel_background_color_dark
              cards_carousel_background_color_light
              cards_carousel_slick_list_height
              cards_carousel_description {
                html
                richText
                text
              }
            }
          }
          ... on PrismicEventPageDataBodyEmbedContent {
            id
            slice_type
            primary {
              embeded_item {
                embed_url
                html
                title
              }
            }
          }
          ... on PrismicEventPageDataBodyYoutubeShorts {
            id
            slice_type
            primary {
              embed_youtube_shorts {
                title
                html
                embed_url
              }
            }
          }
          ... on PrismicEventPageDataBodyFaqSection {
            id
            slice_type
            items {
              text {
                html
                richText
                text
              }
              title {
                html
                richText
                text
              }
            }
            primary {
              faq_background_color_dark
              faq_background_color_light
              faq_description {
                html
                richText
                text
              }
              optional_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyHeader {
            id
            primary {
              title {
                html
                richText
                text
              }
              date
            }
            slice_type
          }
          ... on PrismicEventPageDataBodyImageSection {
            id
            slice_type
            primary {
              full_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
              full_image_link
              full_image_web_link
              full_image_web_modal_text
            }
          }
          ... on PrismicEventPageDataBodyLoggingButton {
            id
            slice_type
            primary {
              logging_button_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
              logging_button_image_id
              logging_button_image_link
              logging_button_web_link
              logging_button_web_modal_text
            }
          }
          ... on PrismicEventPageDataBodyTypeReplaceLogginButton {
            id
            slice_type
            primary {
              replace_logging_button_id
              replace_logging_button_app_link
              replace_logging_button_web_link
              replace_logging_button_web_modal_text
              replace_logging_button_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyShareButton {
            id
            slice_type
            primary {
              share_button_image_id
              share_button_share_link
              share_button_share_text
              share_button_web_link
              share_button_web_modal_text
              share_button_image {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyFloatingButton {
            id
            slice_type
            primary {
              floating_button_background_color_light
              floating_button_background_color_dark
              floating_button_border_radius
              floating_button_bottom_position
              floating_button_button_width
              floating_button_button_height
              floating_button_button_text {
                richText
              }
              floating_button_button_link
              floating_button_button_link_web
              floating_button_button_logging_id
              floating_button_web_modal_text {
                richText
              }
              floating_button_button_text_color
              floating_button_shadow_effect
              floating_button_shadow_effect_color
            }
          }
          ... on PrismicEventPageDataBodyModalButton {
            id
            slice_type
            primary {
              modal_button_button_id
              modal_button_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              modal_button_modal_shadow_effect_color
              modal_button_modal_text
              modal_button_target_uri
              modal_button_target_user
            }
            items {
              modal_button_button_color
              modal_button_button_target_uri
              modal_button_button_text
              modal_button_button_type
            }
          }
          ... on PrismicEventPageDataBodyHeroMedia {
            id
            slice_type
            webmFile {
              localURL
            }
            mp4File {
              localURL
            }
            primary {
              link {
                url
              }
              video_poster {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyAmplitudeEventLog {
            id
            slice_type
            primary {
              amplitude_api_key_production
              amplitude_api_key_alpha
              amplitude_enter_event_name
            }
            items {
              amplitude_event_button_id 
            }
          }
        }
      }
    }
  }
`

export default ContentPage
