import React, { ImgHTMLAttributes } from 'react'
import { styled } from 'gatsby-theme-stitches/src/config'

export const CommonImageContainer: React.FC<
  ImgHTMLAttributes<HTMLImageElement>
> = ({ children, ...props }) => {
  return <ImageContainer {...props}>{children}</ImageContainer>
}

const ImageContainer = styled('div', {
  width: '100%',
  minWidth: '100%',
  marginBlock: -1,
  '& img': {
    '-webkit-user-select': 'none',
    '-webkit-touch-callout': 'none',
    '-webkit-user-drag': 'none',
    '-moz-user-drag': 'none',
    '-o-user-drag': 'none',
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Edge*/,
    'user-select': 'none' /* Future-proof*/,
  },
})

export default CommonImageContainer
