import React from 'react'

export default function LeftArrow({ className, onClick }: any) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76858 0.93451C8.07714 1.24693 8.07714 1.75346 7.76858 2.06588L1.90753 8.0002L7.76858 13.9345C8.07714 14.2469 8.07714 14.7535 7.76858 15.0659C7.46002 15.3783 6.95974 15.3783 6.65118 15.0659L0.231422 8.56588C-0.0771406 8.25346 -0.0771406 7.74693 0.231422 7.43451L6.65118 0.93451C6.95974 0.62209 7.46002 0.62209 7.76858 0.93451Z"
        fill="#212124"
      />
    </svg>
  )
}
