import { useState, useEffect } from 'react'

export default function useDarkModeState() {
  if (typeof window === 'undefined') {
    return
  }
  const [darkmode, setDarkMode] = useState<boolean>(
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  )

  useEffect(() => {
    // dark Mode
    window.document.documentElement.dataset.seed = ''
    const mq = window.matchMedia('(prefers-color-scheme: dark)')

    const apply = () => {
      if (mq.matches) {
        document.documentElement.dataset.seedScaleColor = 'dark'
        setDarkMode(true)
      } else {
        document.documentElement.dataset.seedScaleColor = 'light'
        setDarkMode(false)
      }
    }

    apply()

    if ('addEventListener' in mq) {
      mq.addEventListener('change', apply)
    } else if ('addListener' in mq) {
      // @ts-ignore
      mq.addListener(apply)
    }
  }, [])
  return darkmode
}
