import React from 'react'
import { styled } from '@stitches/react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function HeroSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyHeroMedia
}) {
  const ImageRef = React.useRef<HTMLImageElement>(null)
  const VideoRef = React.useRef<HTMLVideoElement>(null)

  const webmUrl = slice.webmFile?.localURL || ''
  const mp4Url = slice.mp4File?.localURL || ''
  const posterImg = slice.primary.video_poster?.localFile?.childImageSharp?.gatsbyImageData

  const handleLoadedData = () => {
    if (ImageRef.current && VideoRef.current) {
      ImageRef.current.style.display = 'none'
      VideoRef.current.style.display = 'flex'
    }
  }

  return (
    <HeaderSection>
      <CoverWrapper ref={ImageRef}>
        <Cover image={posterImg} alt='이벤트페이지_커버' />
      </CoverWrapper>
      <Video
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={handleLoadedData}
        ref={VideoRef}
      >
        <source src={webmUrl} type="video/webm" />
        <source src={mp4Url} type="video/mp4" />
      </Video>
    </HeaderSection>
  )
}

const HeaderSection = styled('section', {
  width: '100%',
  height: '100vh',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const Video = styled('video', {
  display: 'none',
  border: 'none',
  marginBottom: '-1px',
  width: '100%',
  height: '102%',
  objectFit: 'cover',

})

const CoverWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
})

const Cover = styled(GatsbyImage, {
  width: '100%',
  height: '100%',
})
