import React from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import type { IGatsbyImageData } from 'gatsby-plugin-image'

interface GatsbyImageComponentProps extends Omit<GatsbyImageProps, 'image'> {
  imagefile: IGatsbyImageData
}

export const GatsbyImageComponent: React.FC<GatsbyImageComponentProps> = (
  props,
) => {
  if (!props.imagefile) return null
  return (
    <GatsbyImage
      image={props.imagefile}
      imgStyle={{
        width: '100%',
        willChange: 'auto !important',
        userSelect: 'none',
      }}
      draggable={false}
      {...props}
    />
  )
}

export default GatsbyImageComponent
