import React from 'react'
import { styled } from '@stitches/react'

export default function YoutubeShortsSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyYoutubeShorts
}) {
  const slicePrimary = slice.primary
  if (!slicePrimary.embed_youtube_shorts?.html) return null
  return (
    <VideoWRapper
      dangerouslySetInnerHTML={{ __html: slicePrimary.embed_youtube_shorts.html }}
    ></VideoWRapper>
  )
}

const VideoWRapper = styled('div', {
  width: '100%',
  height: 670,
  paddingBottom: '56%',
  position: 'relative',
  '& > iframe': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
})
