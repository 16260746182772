import React from 'react'
import { styled } from '@stitches/react'

export default function EmbedSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyEmbedContent
}) {
  const slicePrimary = slice.primary
  if (!slicePrimary?.embeded_item?.html) return null
  return (
    <VideoWRapper
      dangerouslySetInnerHTML={{ __html: slicePrimary.embeded_item.html }}
    ></VideoWRapper>
  )
}

const VideoWRapper = styled('div', {
  width: '100%',
  paddingBottom: '56%',
  position: 'relative',
  '& > iframe': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
})
