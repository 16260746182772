import React, { useEffect } from 'react'
import { styled } from '@stitches/react'
import { vars } from '@seed-design/design-token'

interface ModalBackgroundProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ModalBackground: React.FC<ModalBackgroundProps> = ({
  children,
  ...props
}) => {
  useEffect(() => {
    const rootElement = document.body
    rootElement.style.overflow = 'hidden'
    return () => {
      rootElement.style.overflow = 'auto'
    }
  }, [])
  return <ModalBackgroundWrapper {...props}>{children}</ModalBackgroundWrapper>
}

const ModalBackgroundWrapper = styled('div', {
  width: '100%',
  height: '100%',
  position: 'fixed',
  backgroundColor: vars.$semantic.color.overlayDim,
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default ModalBackground
