import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'

import CommonImageContainer from './commons/CommonImageContainer'
import GatsbyImageComponent from './commons/GatsbyImage'

import getGatsbyImage from '../utils/getGatsbyImage'
import { handleLinkButtonClick } from '../utils/handleLinkButtonClick'

export default function LoggingLinkButtonSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyLoggingButton
}) {
  const item = slice.primary
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const image = getGatsbyImage(item.logging_button_image?.localFile)

  return (
    <Fragment>
      <CommonImageContainer
        onClick={() =>
          handleLinkButtonClick(
            postUID,
            item.logging_button_image_id,
            item.logging_button_image_link,
            'normal-btn',
          )
        }
      >
        {image && (
          <GatsbyImageComponent
            imagefile={image}
            alt={item.logging_button_image?.alt ?? ''}
          />
        )}
      </CommonImageContainer>
    </Fragment>
  )
}
