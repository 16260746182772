import React, { Fragment } from 'react'
import { useLocation } from '@reach/router'

import CommonImageContainer from './commons/CommonImageContainer'
import GatsbyImageComponent from './commons/GatsbyImage'

import { handleLinkButtonClick } from '../utils/handleLinkButtonClick'
import getGatsbyImage from '../utils/getGatsbyImage'

export default function ImageSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyImageSection
}) {
  const item = slice.primary
  const image = getGatsbyImage(item.full_image?.localFile)
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const handleClickImage = () => {
    item.full_image_link &&
      handleLinkButtonClick(postUID, null, item.full_image_link)
    return
  }
  if (image) {
    return (
      <Fragment>
        <CommonImageContainer onClick={handleClickImage}>
          <GatsbyImageComponent
            imagefile={image}
            alt={item.full_image?.alt ?? ''}
          />
        </CommonImageContainer>
      </Fragment>
    )
  }
  return null
}
