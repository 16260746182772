import React, { useMemo } from 'react'
import { styled } from '@stitches/react'
import { vars } from '@seed-design/design-token'
import { css } from 'gatsby-theme-stitches/src/config'

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonTheme?: string // define button type variables 'PRIMARY' | 'SECONDARY'
  borderRadius?: number
  fontSize?: TextHeading
  height?: string // absolute px number
  width?: string // percentage unit
  buttonText?: string
}

type ButtonStyleTypes = 'PRIMARY' | 'SECONDARY'

export const Button: React.FC<ButtonProps> = ({
  buttonTheme,
  borderRadius = 5,
  fontSize = 'h5',
  width = '100%',
  buttonText,
  ...buttonProps
}) => {
  const ButtonStyle = useMemo(
    () =>
      css({
        borderRadius: borderRadius,
        width: width,
        margin: '0 auto',
      })(),
    [borderRadius, width],
  )

  const ButtonText = useMemo(
    () =>
      styled(fontSize, {
        color: vars.$static.color.staticWhite,
      }),
    [fontSize],
  )

  return (
    <ButtonWrapper
      {...buttonProps}
      className={ButtonStyle}
      buttonTheme={buttonTheme as ButtonStyleTypes}
    >
      <ButtonText>{buttonText}</ButtonText>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: 10,
  variants: {
    buttonTheme: {
      PRIMARY: {
        backgroundColor: vars.$semantic.color.primary,
      },
      SECONDARY: {
        backgroundColor: vars.$scale.color.gray600,
      },
    },
  },
})
