import React from 'react'
import { styled } from '@stitches/react'
import { vars } from '@seed-design/design-token'

const $scale = vars.$scale

export default function HeaderSlice({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyHeader
}) {
  return (
    <HeaderSection>
      <ArticleTitle> {slice.primary.title?.text}</ArticleTitle>
      <ArticleAddInfo> {slice.primary.date}</ArticleAddInfo>
    </HeaderSection>
  )
}

const HeaderSection = styled('section', {
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 11,
  paddingBottom: 10,
  display: 'inline-flex',
  flexDirection: 'column',
  variants: {
    visible: {
      false: {
        display: 'none',
      },
      true: {
        paddingBottom: 11,
      },
    },
  },
})

const ArticleTitle = styled('span', {
  fontWeight: 'bold',
  fontSize: 20,
  lineHeight: '125%',
  letterSpacing: '-0.03em',
  display: 'inline-flex',
  color: $scale.color.gray900,
})

const ArticleAddInfo = styled('span', {
  fontSize: '13px',
  lineHeight: '150%',
  letterSpacing: '-0.02em',
  display: 'inline-flex',
  color: $scale.color.gray600,
})
