import {
  IOS_APP_DOWNLOAD_URL,
  ANDROID_APP_DOWNLOAD_URL,
} from '../constants/scheme'

export const locateToAppDownLoadUrl = () => {
  const deviceType = navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|Macintosh|Windows/i,
  )?.[0]

  switch (deviceType) {
    case 'iPhone':
    case 'iPod':
    case 'Macintosh':
      window.location.href = IOS_APP_DOWNLOAD_URL
      break
    case 'Android':
    case 'BlackBerry':
    case 'Windows':
    default:
      window.location.href = ANDROID_APP_DOWNLOAD_URL
      break
  }
}
