import {
  installKarrotBridgeDriver,
  makeKarrotBridge,
} from '@daangn/karrotbridge'
import { captureMessage } from '@sentry/gatsby'
import React, { createContext, useContext } from 'react'

type Event = {
  target: 'FIREBASE' | 'KARROT'
  name: string
  params?: {
    [key: string]: string | number | boolean | null | undefined
  }
}
export const bridge = (() => {
  if (typeof window === 'undefined') {
    return null
  }

  const { driver } = installKarrotBridgeDriver()
  const bridgeInstance = makeKarrotBridge({ driver })

  return bridgeInstance
})()

export const logAnalyticsEvent = (
  name: string,
  params?: Event['params'],
): void => {
  const event: Omit<Event, 'params'> & { params: string } = {
    target: 'FIREBASE',
    name,
    params: JSON.stringify(params ? { ...params } : {}),
  }
  try {
    bridge?.logEvent({ analytics: event })
  } catch (err) {
    captureMessage('로그를 생성하는 도중 에러가 발생했어요.')
  }
  return
}

const BridgeContext = createContext(bridge)

export const useBridge = () => {
  return useContext(BridgeContext)
}

export const BridgeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <BridgeContext.Provider value={bridge}>{children}</BridgeContext.Provider>
  )
}
