import React from 'react'
import { styled } from '@stitches/react'
import { GatsbyImage } from 'gatsby-plugin-image'

import getGatsbyImage from '../../utils/getGatsbyImage'

const SlideItem: React.FC<
  Queries.PrismicPageDataBodyCardsCarouselItem &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>
> = ({ image, ...props }) => {
  const imageItem = getGatsbyImage(image?.localFile)
  return (
    <Container {...props}>
      {image && (
        <ImageContainer>
          {imageItem && (
            <GatsbyImage
              image={imageItem}
              alt={image.alt ?? ''}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ImageContainer = styled('div', {
  width: '100%',
  paddingBottom: '100%',
  position: 'relative',
})

export default SlideItem
