import { logAnalyticsEvent } from '../../../bridge'

export type ButtonLoggingType = {
  post_id: string
  country: CountryCode
  button_id: string | null
}

export const createButtonClickLog = (
  params: ButtonLoggingType,
  type: string,
) => {
  if (params.button_id) {
    logAnalyticsEvent('click_event_page_normal_button', {
      ...params,
      type,
    })
  }
}

export default createButtonClickLog
