const payWebViewScheme = ['karrot://pay-web', 'karrot.alpha://pay-web']

export const payWebviewAvailableVersionCheck = (
  version: string,
  os: OStype,
) => {
  const ANDROID_MINIMAL_VERSION = 62900
  const IOS_MINIMAL_VERSION = 64900
  if (version && os) {
    if (os === 'Android' && +version < ANDROID_MINIMAL_VERSION) {
      return false
    } else if (os === 'iOS' && +version < IOS_MINIMAL_VERSION) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function isPayWebViewScheme(scheme?: string | null) {
  if (!scheme) return false
  return payWebViewScheme.some((s) => scheme.indexOf(s) > -1)
}
