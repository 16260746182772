import React, { useEffect, useMemo, useState } from 'react'
import { isProductionURL } from '../utils/check'
import { initAmplitude, trackAmplitudeEvent } from '../utils/event/amplitude'
import { useBridge } from '../../../bridge'
import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'

export default function AmplitudeEventLogSlice({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyAmplitudeEventLog
}) {
  const slicePrimary = slice.primary
  const buttonIdList = slice.items ?? [];

  const bridge = useBridge()
  const [isMountedAmplitude, setIsMountedAmplitude] = useState(false);

  const initAmplitueUserInfo = async (amplitudeApiKey: string) => {
    const [userInfo, userRegion] = await Promise.all([
      bridge?.getUserInfo({}),
      bridge?.getRegionInfo({})
    ]) as [KarrotBridgeSchema["ReqInfoUserResponse"], KarrotBridgeSchema["ReqInfoRegionResponse"]];

    initAmplitude({
      apiKey: amplitudeApiKey ?? '',
      userId: (userInfo.info.user.id ?? '').toString() as string,
      region: {
        ...userRegion.info.region,
      }
    })
  }

  useEffect(() => {
    const amplitudeApiKey = isProductionURL(window?.location?.href ?? '') ? slicePrimary.amplitude_api_key_production : slicePrimary.amplitude_api_key_alpha

    if (!amplitudeApiKey) {
      return
    }

    (async () => {
      try {
        await initAmplitueUserInfo(amplitudeApiKey)

        setIsMountedAmplitude(true)
        trackAmplitudeEvent(slicePrimary?.amplitude_enter_event_name ?? '')
      } catch {
        setIsMountedAmplitude(false)
      }
    })();
  }, [
    slicePrimary.amplitude_api_key_production, 
    slicePrimary.amplitude_api_key_alpha,
  ])

  useEffect(() => {
    if (!isMountedAmplitude) {
      return
    }

    const handleClickEvent = (event: any) => {
      trackAmplitudeEvent(`click_${event.currentTarget.id}`)
    }

    const elementList = buttonIdList.map(({ amplitude_event_button_id }) => document.getElementById(amplitude_event_button_id ?? ''));

    elementList.forEach((element) => {
      if (element) {
        element.addEventListener('click', handleClickEvent)
      }
    });

    return () => {
      elementList.forEach((element) => {
        if (element) {
          element.removeEventListener('click', handleClickEvent)
        }
      });
    }
  }, [isMountedAmplitude]);

  return <React.Fragment></React.Fragment>;
}
