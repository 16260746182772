import { identify as setIdentify, Identify, init, track, setUserId } from '@amplitude/analytics-browser'

type AmplitudeConfig = {
  apiKey: string
  userId: string
  region: {
    name: string,
    name1: string,
    name2: string,
    name3: string,
  }
}

export async function initAmplitude({ apiKey, ...config }: AmplitudeConfig) {
  await init(apiKey, undefined, {
    attribution: {
      trackNewCampaigns: true,
    }
  })

  setUserData(config)
};

const setUserData = ({ userId, region }: Omit<AmplitudeConfig, 'apiKey'>) => {
  const identify = new Identify()

  setUserId(`karrot-${userId}`)
  identify.set('checkinCity', region.name1)
  identify.set('checkinGu', region.name2)
  identify.set('checkinDong', region.name3)
  identify.set('checkinMostAccurate', region.name)

  setIdentify(identify)
}

export const trackAmplitudeEvent = (eventName: string, eventProperties?: Record<string, any>) => {
  track(eventName, eventProperties)
}
