export const isProductionURL = (url: string) => {
  if (!url) return false 
  if (url.includes('.alpha')) return false
  if (url.includes('local')) return false

  return true
}

export const isDevelopmentURL = (url: string) => {
  return !isProductionURL(url)
}
