import React, { Fragment, useState } from 'react'
import { useLocation } from '@reach/router'

import CommonImageContainer from './commons/CommonImageContainer'
import ModalBackground from './commons/ModalBackground'
import ModalPopupContainer from './commons/ModalPopup'
import GatsbyImageComponent from './commons/GatsbyImage'

import { handleLinkButtonClick } from '../utils/handleLinkButtonClick'
import createButtonClickLog from '../utils/createButtonClickLog'
import getGatsbyImage from '../utils/getGatsbyImage'

export default function ModalButtonSection({
  slice,
}: {
  slice: Queries.PrismicEventPageDataBodyModalButton
}) {
  const item = slice.primary
  const buttonItems = slice.items
  const image = getGatsbyImage(item.modal_button_image?.localFile)
  const location = useLocation()
  const postUID = location.pathname.split('/')[2]
  const [modalActive, setModalActive] = useState<boolean>(false)

  const handleClickImage = () => {
    switch (item.modal_button_target_user) {
      case 'ALL':
        createButtonClickLog(
          {
            post_id: postUID,
            button_id: item.modal_button_button_id,
            country: process.env.COUNTRY_CODE as CountryCode,
          },
          'modal-btn-target-user-click',
        )
        setModalActive(true)
        break
      default:
        item.modal_button_target_uri &&
          handleLinkButtonClick(
            postUID,
            item.modal_button_button_id,
            item.modal_button_target_uri,
            'modal-btn-nontarget-user-click',
          )
        break
    }
    return
  }

  const handleClicMoveUrlButton = (url?: string) => {
    if (url) {
      handleLinkButtonClick(
        postUID,
        item.modal_button_button_id + '-positive-button',
        url,
        'modal-btn-positive-button',
      )
    }
    setModalActive(false)
    return
  }

  const handleClickModalOffButton = () => {
    setModalActive(false)
  }

  const handleClickOutBoundOfModal = () => {
    setModalActive(false)
  }

  if (image) {
    return (
      <Fragment>
        <CommonImageContainer onClick={handleClickImage}>
          <GatsbyImageComponent
            imagefile={image}
            alt={item.modal_button_image?.alt ?? ''}
          />
        </CommonImageContainer>
        {modalActive && (
          <ModalBackground>
            <ModalPopupContainer
              title={item.modal_button_modal_text}
              buttonItems={buttonItems}
              shadow={item.modal_button_modal_shadow_effect_color}
              onClickMoveURLButton={handleClicMoveUrlButton}
              onClickModalOffButton={handleClickModalOffButton}
              onClickOutBoundModal={handleClickOutBoundOfModal}
            />
          </ModalBackground>
        )}
      </Fragment>
    )
  }
  return null
}
